import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link, useLocation} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import TeamSelection from "./TeamSelection";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { DIGITAL_PLATFORMS_FOLDER_ID, RAI_RTI_PROGRAMS_FOLDER_ID } from "../../constants/specialFolders";


// import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
const qs = require('querystring');

const folderRoute = (folderId) => {
  let route = ''
  switch (folderId) {
    // case ABBONATI_SKY_FOLDER_ID:
    //   route = '/documents/abbonati-sky'
    //   break
    // case DOCUMENTI_ECONOMICO_FINANZIARI_ID:
    //   route = '/documents/documenti-economico-finanziari'
    //   break
    case DIGITAL_PLATFORMS_FOLDER_ID:
      route = '/documents/digital-platforms'
      break
    case RAI_RTI_PROGRAMS_FOLDER_ID:
      route = '/documents/audience-programmi-rai-rti'
      break
    default:
      route = `/documents/folder/${folderId}`
  }
  return route
}

const SidebarContent = () => {
  const location = useLocation();
  let {navStyle, themeType } = useSelector(({settings}) => settings);

  const {folders} = useSelector((state) => state.document);
  const {profile} = useSelector((state) => state.user);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const filteredFolderKeys = (foldersData) => {
    return Object.keys(foldersData).sort((a,_) => foldersData[a].name === 'My Club Matchday' ? -1 : 0)
  }

  // const folder = 'home';
  // const son = 'home';

  let folder = null;
  let son = null;

  if (location.state) {
    folder = location.state.folder
    son = location.state.son
  } else {
    // try get folderId from URL
    const URLParams = qs.parse(location.pathname.includes('?')
        ? location.pathname.split('?')[1]
        : location.search.slice(1)
    );

    // folder is father of current folder (folderId)
    folder = folders.data && URLParams.folderId && folders.data[URLParams.folderId]
      ? folders.data[URLParams.folderId].parent
      : null
    son = URLParams.folderId ? URLParams.folderId : 'home';
  }

  return (<>
      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          {/* change this name to TeamList */}
          <TeamSelection/>

          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[location && location.state
              ? location.state.folder
              : folder !== null
                ? folder.toString()
                : 'home'
            ]}
            selectedKeys={[location && location.state
              ? location.state.son
                ? location.state.son
                : location.state.folder
              : son !== null
                ? son
                : 'home'

            ]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
            className="sidebar-menu">

            <Menu.Item key="home">
              <Link to={{
                  pathname: "/home",
                  state: {
                    folder: 'home',
                    son: null
                  }
              }}>
                <span> Home </span>
              </Link>
            </Menu.Item>

            {
              folders.status === 'complete' || folders.data !== null
              ? (
                filteredFolderKeys(folders.data).map((folder) => (
                  folders.data[folder].subfolders.length > 0
                    ? (
                      // sub menu
                      <SubMenu key={folder} className={`${getNavStyleSubMenuClass(navStyle)}`} title={
                        <span>
                          {folders.data[folder].name}
                        </span>
                      }>
                        {
                          folders.data[folder].name === 'My Club Matchday' ?
                          (
                            <Menu.Item key="audiencetv">
                              <Link to={{
                                pathname: "/matchday/total-audience-dirette",
                                state: {
                                  folder: folder,
                                  son: 'total-audience-dirette'
                                }
                              }}>
                                <span> Total Audience Dirette </span>
                              </Link>
                            </Menu.Item>
                          )
                          :
                          null
                        }
                        {
                          folders.data[folder].subfolders.filter((subfId) => typeof folders.data[subfId] !== 'undefined').map((subfId) => (
                            // this key must match the one specified in defaultOpenKeys
                            // and selectedKeys
                            <Menu.Item key={subfId}>
                              <Link to={{
                                // pathname: `/documents/folder/${subfId}`,
                                pathname: folderRoute(subfId),
                                state: {
                                  folder: folder,
                                  son: subfId.toString(),
                                  folderObj: folders.data[subfId]
                                }
                              }}>
                                  {folders.data[subfId].name}
                              </Link>
                            </Menu.Item>
                          ))
                        }
                      </SubMenu>
                    ) : (
                      folders.data[folder].parent === null
                        ? (
                          <Menu.Item key={folder}>
                            <Link to={{
                              // pathname: `/documents/?folderId=${folder}`,
                              pathname: folderRoute(folder),
                              state: {
                                folder: folder,
                                son: null,
                                folderObj: folders.data[folder]
                              }
                            }}>
                                {folders.data[folder].name}
                            </Link>
                          </Menu.Item>
                        )
                        : ''
                    )
                ))
              )
              : folders.status === 'loading'
                ? ''
                : folders.status === 'idle'
                  ? ''
                  : 'Aggiorna la pagina.'
            }
            {
              profile && profile.permissions.includes('view_user') &&
              <Menu.Item key="accesses">
                <Link to={{
                    pathname: "/accesses",
                    state: {
                      folder: 'accesses',
                      son: null
                    }
                }}>
                  {"Gestione degli accessi"}
                </Link>
              </Menu.Item>
            }
            {
              profile && profile.is_lega && profile.groups.includes('Amministratore Lega') &&
              <Menu.Item key="upload_audience">
                <Link to={{
                    pathname: "/upload_audience",
                    state: {
                      folder: 'upload_audience',
                      son: null
                    }
                }}>
                  {"Upload Audience"}
                </Link>
              </Menu.Item>
            }
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

