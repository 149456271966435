import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({match}) => {
  return (
    <div className={`gx-main-content-wrapper`}>
      <Switch>
        <Route exact path={`${match.url}home`} component={asyncComponent(() => import('./home'))}/>
        <Route exact path={`/matchday/total-audience-dirette`} component={asyncComponent(() => import('./matchday/audiencetv/'))}/>
        <Route exact path={`/documents/audience-programmi-rai-rti`} component={asyncComponent(() => import('./documents/RaiRtiPrograms'))}/>
        <Route exact path={`/detail`} component={asyncComponent(() => import('./detail'))}/>
        <Route exact path={`/matchday/detail`} component={asyncComponent(() => import('./detail'))}/>

        <Route path={`/documents/abbonati-sky`} component={asyncComponent(() => import('./abbonati-sky'))}/>
        <Route path={`/documents/documenti-economico-finanziari`} component={asyncComponent(() => import('./documenti-economico-finanziari'))}/>
        <Route path={`/documents/digital-platforms`} component={asyncComponent(() => import('./documents/DigitalPlatforms'))} />
        <Route path={`/documents/folder/:folderId`} component={asyncComponent(() => import('./documents'))}/>

        <Route path={`/accesses`} component={asyncComponent(() => import('./accesses'))}/>
        <Route path={`/upload_audience`} component={asyncComponent(() => import('./upload_audience'))}/>
        <Route path={`/user-profile`} component={asyncComponent(() => import('./user-profile'))}/>

      </Switch>
    </div>
  )

};

export default App;
